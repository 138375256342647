import {
    StorageAcceptanceLogicSealingPipelineSectorStatesFields,
    StorageAcceptanceLogicAdditionalFields,
    StorageAcceptanceLogicSealingPipelineSectorStatesErrorsFields,
} from '../utils/storage-acceptance-logic-query-fields'

export const PlaygroundSealingPipelineSectorStatesValues = (t) => {
    return StorageAcceptanceLogicSealingPipelineSectorStatesFields().map(
        function (value) {
            return {
                label: t('sealingpipeline.fields.' + value),
                value: value,
            }
        }
    )
}

export const PlaygroundSealingPipelineSectorStatesErrorsValues = (t) => {
    return StorageAcceptanceLogicSealingPipelineSectorStatesErrorsFields()
        .filter((value) => value !== 'Any')
        .map(function (value) {
            return {
                label: t('sealingpipeline.fields.' + value),
                value: value,
            }
        })
}

/* 
    For Additional fields in Playground, we will not create ReceivedOnTimeOfDayUTC and ReceivedOnDayOfWeek
    but only one field, with type datetime to select the datetime at which the proposal was received/processed.
*/
export const PlaygroundOtherAcceptanceLogicValues = (t) => {
    return StorageAcceptanceLogicAdditionalFields()
        .filter(
            (value) =>
                value.label !== 'ReceivedOnTimeOfDayUTC' &&
                value.label !== 'ReceivedOnDayOfWeek'
        )
        .map(function (value) {
            return {
                label: t('sealingpipeline.fields.' + value.label),
                value: value.label,
            }
        })
}

export const GetPlaygroundAccepanceLogicFields = (selectedValues, t) => {
    const valuesToFilter = selectedValues.map((element) => element.state)

    const options = [
        {
            label: t('sealingpipeline.fields.sectorStatesTitle'),
            options: PlaygroundSealingPipelineSectorStatesValues(t).filter(
                (element) => valuesToFilter.indexOf(element.label) <= -1
            ),
        },
        {
            label: t('sealingpipeline.fields.sectorStatesErrorsTitle'),
            options: PlaygroundSealingPipelineSectorStatesErrorsValues(
                t
            ).filter((element) => valuesToFilter.indexOf(element.label) <= -1),
        },
        {
            label: t('sealingpipeline.fields.additionalFieldsTitle'),
            options: [
                ...PlaygroundOtherAcceptanceLogicValues(t).filter(
                    (element) => valuesToFilter.indexOf(element.label) <= -1
                ),
            ],
        },
    ]

    // Here, we will create a new field for timestamp in additional fields section
    // this will combine day of wek, and time of the day fields into single field.
    if (valuesToFilter.indexOf('ReceivedOnDatetime') <= -1) {
        options[1].options.push({
            label: t('sealingpipeline.fields.ReceivedOnDatetime'),
            value: 'ReceivedOnDatetime',
        })
    }

    return options
}
