import { getCurrentFilecoinEpoch } from '../../shared/utils/filecoinUtil'
import { convertBytesToGiB } from '../../shared/utils/file_size'

import {
    StorageAcceptanceLogicSealingPipelineSectorStatesFields,
    StorageAcceptanceLogicAdditionalFields,
    StorageAcceptanceLogicSealingPipelineSectorStatesErrorsFields,
} from '../../shared/utils/storage-acceptance-logic-query-fields'

const dayjs = require('dayjs')

export const convertToDealProposal = (
    values,
    startEpochSealingBufferSettings,
    shouldConvertFromNanoFil
) => {
    // First convert a json array to a string array
    const storageAcceptanceLogicSectorStatesError =
        StorageAcceptanceLogicSealingPipelineSectorStatesErrorsFields().map(
            (element) => element
        )

    const storageAcceptanceLogicSectorStates =
        StorageAcceptanceLogicSealingPipelineSectorStatesFields().map(
            (element) => element
        )

    const storageAcceptanceLogicAdditionalFields =
        StorageAcceptanceLogicAdditionalFields().map(
            (element) => element['label']
        )

    // Parse the sealing pipeline values (storage acceptance logic)
    // We need to parse one side the sealing pipeline sector state and other side the "Other" variables
    const sealingPipelineSectorStates = values.sealingPipelineValuesToSimulate
        .filter(
            (item) =>
                !storageAcceptanceLogicAdditionalFields.includes(item.state) &&
                storageAcceptanceLogicSectorStates.includes(item.state)
        )
        .reduce(
            (obj, item) =>
                Object.assign(obj, { [item.state]: parseInt(item.value) }),
            {}
        )

    // Do the same function for errors sector states
    const sealingPipelineSectorStatesError =
        values.sealingPipelineValuesToSimulate
            .filter(
                (item) =>
                    !storageAcceptanceLogicAdditionalFields.includes(
                        item.state
                    ) &&
                    storageAcceptanceLogicSectorStatesError.includes(item.state)
            )
            .reduce(
                (obj, item) =>
                    Object.assign(obj, { [item.state]: parseInt(item.value) }),
                {}
            )

    // For additional fields, we need to do some conversion if needed
    // In some case, BaseFee need to be converted from NanoFil to AttoFil
    /*const additionalFieldsProposalData = values.sealingPipelineValuesToSimulate
        .filter(
            (item) =>
                !StorageAcceptanceLogicSealingPipelineSectorStatesFields().includes(
                    item.state
                )
        )
        .reduce((obj, item) => {
            return item.state === 'BaseFee' && shouldConvertFromNanoFil ? 
                Object.assign(obj, { [item.state]: Number(parseFloat(item.value) * 1e9) })
                : item.state === 'ReceivedOnDatetime' ?
                Object.assign(obj, { [item.state]: dayjs(item.value * 1000).unix() })
                : Object.assign(obj, { [item.state]: parseInt(item.value) })
        }, {})
    */

    const additionalFieldsProposalData = values.sealingPipelineValuesToSimulate
        .filter(
            (item) =>
                !StorageAcceptanceLogicSealingPipelineSectorStatesFields().includes(
                    item.state
                )
        )
        .reduce((obj, item) => {
            return item.state === 'ReceivedOnDatetime'
                ? Object.assign(obj, {
                      [item.state]: dayjs(item.value * 1000).unix(),
                  })
                : Object.assign(obj, { [item.state]: parseInt(item.value) })
        }, {})

    // We will send directly a CIDgravity standardized proposal to deal filter
    const pieceCID = { '/': 'cidgravity_playground' }
    const proposalCID = { '/': 'cidgravity_playground' }
    const clientSignature = { Type: 2, Data: 'cidgravity_playground' }
    const currentFilecoinEpoch = getCurrentFilecoinEpoch()

    // Price is already in attoFil
    // But we need to multiplicate the price with the deal size in GiB to send the correct price
    // Because in real deal proposal the price is for total deal size / epoch
    // to avoid attoFil with commas in deal proposal, round down the result
    const priceAsAttoFil = Math.ceil(values.dealPrice * convertBytesToGiB(values.dealSize))

    // For the start epoch, we need to add few epochs to avoid rejecting due to start epoch sealing bufer checks
    // For now 2880 epochs should be enough (2880 epochs = 24 hours)
    const epochsAddedToAvoidSealingBufferChecks = 2880

    // We need to add "fake" values at the moment due to field checker in deal filter
    const proposal = {
        PieceCID: pieceCID,
        PieceSize: values.dealSize,
        VerifiedDeal: values.dealVerified,
        Client: values.fromAddress,
        Provider: 'cidgravity_playground',
        Label: 'cidgravity_playground',
        StartEpoch:
            currentFilecoinEpoch +
            startEpochSealingBufferSettings +
            epochsAddedToAvoidSealingBufferChecks,
        EndEpoch:
            currentFilecoinEpoch +
            epochsAddedToAvoidSealingBufferChecks +
            startEpochSealingBufferSettings +
            values.dealDuration,
        StoragePricePerEpoch: priceAsAttoFil,
        ProviderCollateral: '0',
        ClientCollateral: '0',
    }

    return {
        Proposal: proposal,
        ClientSignature: clientSignature,
        ProposalCID: proposalCID,
        TransferType: values.dealTransferType,
        FastRetrieval: false,
        DealType: 'storage',
        FormatVersion: '2.2.0',
        Agent: 'playground',
        Additional: additionalFieldsProposalData,
        SealingPipelineState: {
            SectorStates: sealingPipelineSectorStates,
            SectorStatesErrors: sealingPipelineSectorStatesError,
        },
    }
}

export const ParseStandardizedProposalToAcceptanceLogicValues = (
    deal,
    standardizedProposal
) => {
    const results = []

    // First, add one field called ReceivedOnDatetime based on receivedOn proposal field */
    results.push({
        state: 'ReceivedOnDatetime',
        value: dayjs(deal.receivedOn).unix(),
    })

    // get sector states
    const sectorStates = standardizedProposal.SealingPipelineState.SectorStates
    const sectorStatesErrors =
        standardizedProposal.SealingPipelineState.SectorStatesErrors

    for (let i in sectorStates) {
        results.push({ state: i, value: sectorStates[i] })
    }

    for (let i in sectorStatesErrors) {
        results.push({ state: i, value: sectorStatesErrors[i] })
    }

    // get additionnal fields (fil price, base fee ...)
    // remove fields that no need to appear on frontend
    const additionalFields = standardizedProposal.Additional

    // because of query builder configuration, we need to convert the array firsts
    const storageAcceptanceLogicAdditionalFields =
        StorageAcceptanceLogicAdditionalFields().map(
            (element) => element['label']
        )

    // For Additional fields in Playground, we will ignore ReceivedOnTimeOfDayUTC and ReceivedOnDayOfWeek */
    for (let i in additionalFields) {
        if (
            storageAcceptanceLogicAdditionalFields.includes(i) &&
            i !== 'ReceivedOnTimeOfDayUTC' &&
            i !== 'ReceivedOnDayOfWeek'
        ) {
            results.push({ state: i, value: additionalFields[i] })
        }
    }

    return results
}

export const getPaddingForDealSize = (value) => {
    return Math.pow(2, Math.ceil(Math.log2(value)))
}
